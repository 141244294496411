import React, { useState } from "react";
import { graphql } from "gatsby";
import { SEO } from "../components/SEO";
import Img from "gatsby-image";
import Lightbox from "react-image-lightbox";

export const pageQuery = graphql`
  query GalleryImage($category: String!) {
    missingImage: file(relativePath: { eq: "img/image-missing.png" }) {
      childImageSharp {
        fluid(
          maxHeight: 300
          maxWidth: 300
          fit: CONTAIN
          background: "#ffffff"
        ) {
          src
          ...GatsbyImageSharpFluid
        }
      }
    }
    drupalGalleryImage: allNodeGalleryImage(
      filter: { relationships: { field_category: { name: { eq: $category } } } }
      sort: { fields: created, order: DESC }
    ) {
      edges {
        node {
          id
          title
          field_price
          relationships {
            field_category {
              relationships {
                node__gallery_overview {
                  title
                }
              }
            }
            thumb: field_image_gallery {
              localFile {
                childImageSharp {
                  fluid(
                    maxHeight: 300
                    maxWidth: 300
                    fit: CONTAIN
                    background: "#ffffff"
                  ) {
                    src
                    ...GatsbyImageSharpFluid
                  }
                }
              }
            }
            normal: field_image_gallery {
              localFile {
                childImageSharp {
                  original {
                    src
                  }
                }
              }
            }
          }
        }
      }
    }
  }
`;

export default function Gallery(props: any) {
  const data = props.data;

  const edges = data.drupalGalleryImage.edges;
  const getImage = (id: any): string => {
    const lightBoxImage = edges
      .filter((edge: any) => {
        return id === edge.node.id;
      })
      .map(
        (edge: any) =>
          (edge.node.relationships.normal?.localFile &&
            edge.node.relationships.normal.localFile.childImageSharp.original
              .src) ||
          data.missingImage.childImageSharp.fluid.src
      )[0];
    return lightBoxImage;
  };
  const seoTitle =
    typeof edges !== "undefined" && edges.length > 0
      ? edges[0].node.relationships.field_category.relationships
          .node__gallery_overview[0].title
      : "Gallerie Eintrag";

  const [lightBoxProps, setLightBoxProps] = useState({
    show: false,
    edgeId: 0
  });

  return (
    <>
      <SEO title={seoTitle} metaDescription={seoTitle} />

      <div className="m-3">
        <div className="grid gap-2 row-gap-2 grid-cols-1 sm:grid-cols-2 md:grid-cols-3 lg:grid-cols-4">
          {edges.map((edge: any) => {
            const id = edge.node.id;
            const title =
              edge.node.title + " ab " + edge.node.field_price + " €";
            let thumbFluid =
              edge.node.relationships.thumb?.localFile?.childImageSharp.fluid;
            if (!thumbFluid) {
              thumbFluid = data.missingImage.childImageSharp.fluid;
            }
            return (
              <div
                key={id}
                className="cursor-pointer p-2 text-center border rounded-lg border-opacity-50 h-full"
                onClick={() =>
                  setLightBoxProps(() => {
                    return { edgeId: id, show: true };
                  })
                }
              >
                <Img fluid={thumbFluid} title={title} alt={title} />
                <span className="text-xs">{title}</span>
              </div>
            );
          })}
        </div>
        {lightBoxProps.show && (
          <Lightbox
            mainSrc={getImage(lightBoxProps.edgeId)}
            onCloseRequest={() =>
              setLightBoxProps(old => {
                return { ...old, show: false };
              })
            }
          />
        )}
      </div>
    </>
  );
}
